import React from "react";
import { Link } from "gatsby";

import Layout from "../components/layout";

function IndexPage() {
  return (
    <Layout>
      <p style={{ fontWeight: 700, textTransform: "uppercase" }}>
        <span role="img" aria-label="construction barricade emoji">
          🚧
        </span>{" "}
        Under construction{" "}
        <span role="img" aria-label="construction barricade emoji">
          🚧
        </span>
      </p>
    </Layout>
  );
}

export default IndexPage;
