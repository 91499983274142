/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";

import "../styles/layout.css";

const Layout = ({ children }) => {
  return (
    <div
      className="system-ui"
      style={{
        maxWidth: "40rem",
        margin: "2rem auto",
        textAlign: "center"
      }}
    >
      <header style={{ padding: "0 2rem" }}>
        <h1 style={{ fontSize: "4rem", fontWeight: 900, marginBottom: "1rem" }}>
          ALL CAPS BOLD
        </h1>
        <p>A development studio in Lexington, Kentucky.</p>
      </header>
      <hr
        style={{
          margin: "2rem 0",
          borderTop: "none",
          borderBottom: "1px solid #a0a0a0"
        }}
      />
      <main>{children}</main>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
